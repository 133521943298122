* {
  box-sizing: border-box;

}
.f-18{
font-size: 18px;
text-align: justify;
text-justify: inter-word;
}
.f-20{
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
  }
  .f-22{
    font-size: 22px;
    text-align: justify;
    text-justify: inter-word;
    }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bgOrange {
  background-color: #ff4d29;

}

.text-pink {
  color: #d754a2;

}

.btn-orange {
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
}

.column {
  column-count: 2;
}

.bgGray {
  background-color: #efefef;

}

.btn-pink:hover {
  color: #000;
  background-color: #d754a2;
}

.btn-pink-outline-warning {
  border: 2px solid #d754a2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/*custom css below*/
.navbar-nav .dropdown a {
  color: black;
}

footer {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)) no-repeat border-box, url('./Assets/footerimg.jpg');
  background-size: cover;
}

.lightTxt {
  color: #ADB3B9;
}

.termsimg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/slider/slide1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}

.bglight {
  background-color: #F8F9FA;
}

.containerImg {
  position: relative;
  text-align: center;
  color: white;
}
.containerImgHome{
  position: relative;
  text-align: center;
  color: white;
}
.homeImg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/new/a.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.bannerImg {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/slider/slide1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}
.trainingImg{
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/new/slide3.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}
.softImg{
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/slider5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homeText{
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translate(-50%, -50%);
}
.aboutCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aboutinfoimg {
  background: linear-gradient(rgba(255, 77, 41, 0.8), rgba(255, 77, 41, 0.8)), url('./Assets/footerimg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}

.textOnImg {
  position: relative;
  color: white;

}

.rotate:hover {

  rotate: 360deg;
  transition-duration: 1s;

}

.annimatedBorder:hover {

  border-bottom: 1px solid #ff4d29;

}

.sliderImg {
  background-image: url('../src/Assets/bg_banner2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}
.hoverColor:hover{
  background-color: #e6eaee;
  box-shadow: 2px 2px #61dafb;
}
.text {
  padding: 0;
  margin: 0;
  transform: translateX(-200%);
  opacity: 0;
  animation: slide-in-anim 1.5s ease-out forwards;
}


.homeContainer {
  position: relative;
}

.homeImg1 {
  background-image: url('../src/Assets/new/b.jpg');
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.homeImg1 h1 {
  position: absolute;
  width: 90%;
  text-align: center;
  top: 40%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);

font-size: 58px;
font-weight: bold;
}
.example1 {
  height: 50px;	
  overflow: hidden;
  position: relative;
 }
 .example1 .marquee {
  /* font-size: 3em; */
  /* color: limegreen; */
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  /* line-height: 50px; */
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);	
  transform:translateX(100%);
  /* Apply animation to this element */	
  -moz-animation: example1 20s linear infinite;
  -webkit-animation: example1 20s linear infinite;
  animation: example1 20s linear infinite;
 }
 /* Move it (define the animation) */
 @-moz-keyframes example1 {
  0%   { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
 }
 @-webkit-keyframes example1 {
  0%   { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
 }
 @keyframes example1 {
  0%   { 
  -moz-transform: translateX(100%); /* Firefox bug fix */
  -webkit-transform: translateX(100%); /* Firefox bug fix */
  transform: translateX(100%); 		
  }
  100% { 
  -moz-transform: translateX(-100%); /* Firefox bug fix */
  -webkit-transform: translateX(-100%); /* Firefox bug fix */
  transform: translateX(-100%); 
  }
 }
.marqueefont{
  font-size: 20px;
}
.textJustify{
  text-align: justify;
  text-justify: inter-word;
}
.homeCards{
  height: 150px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeCards:hover{
 
    rotate: 360deg;
    transition-duration: 2s;
  
}
.homeHeading{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;

}
.homebox{
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgBanner{
  background-color: #4c4f51;
  /* background-image: url('../src/Assets/banner/1.png'); */
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  min-height: 90vh;

}
.orangeBorder{
  border: 2px solid #ff4d29;
  border-radius: 5px;
}
.orangeBorder:hover{
  background-color: #ff4d29;
  color: white;

}
.text-theme{
  color: #ff4d29;

}
@media only screen and (max-width: 600px) {
  .bannerImg {
    min-height: 40vh;
  }

  .aboutCentered {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: none;
  }
  .homeImg {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./Assets/new/a.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 50vh;
  }
}
